.profile {
    @apply gap-[16px] h-screen;
}
.profile:not(.mobile-view) {
    @apply lg:block hidden m-6;
}
.profile.mobile-view {
    @apply lg:hidden;
}
.profile__content {
    @apply grid gap-[16px];
}
.profile__block {
    @apply px-[12px] py-[16px] sm:p-[24px] border-t border-b lg:border border-[#E4E4E7] overflow-hidden bg-white lg:rounded-xl;
}
.profile__block-text {
    @apply text-[#51525c] text-sm lg:text-base;
}
.profile__user {
    @apply grid grid-cols-[auto_1fr] gap-[16px] lg:gap-[24px] items-center;
}
.profile__user-icon {
    @apply bg-[#F4F4F5] w-[88px] h-[88px] lg:w-[128px] lg:h-[128px] flex items-center justify-center rounded-xl;
}
.profile__user-icon img {
    width: 40%;
    height: 40%;
    object-fit: contain;
}
.profile .text-mb {
    @apply mb-[4px];
}
.profile .title-mb {
    @apply mb-[8px];
}
.profile .mb-el {
    @apply mb-[24px];
}
.profile .divider {
    @apply bg-[#E4E4E7] h-px my-[16px] lg:my-[24px];
}
.profile .primary-btn-style {
    @apply py-2 px-3 h-auto bg-[#18181B];
}

@media screen and (max-width: 375px) {
    .profile__user {
        @apply grid-cols-1;
    }
}
