.auth {
    @apply w-screen h-screen flex justify-center items-center;
    background: radial-gradient(100% 100% at 50% 0%, #282828 0%, #000 100%);
}

.auth__block {
    @apply w-full max-w-[444px] py-[24px] px-[20px] sm:p-[32px] bg-white rounded-lg m-[10px];
    box-shadow: 0px 4px 70.3px 0px rgba(0, 0, 0, 0.08);
}

.auth__block .ant-form-item-control-input-content {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.auth__block .ant-form-item-label {
    width: 100%;
}

.auth__title {
    @apply mt-[30px] text-4xl font-semibold text-center text-primary;
    font-family: 'Stagnan';
    font-weight: 600;
}

.auth__subtitle {
    @apply mt-[12px] text-[#667085] text-center;
}

.auth__subtitle span {
    @apply text-[#101828];
}

.auth__form {
    @apply mt-8;
}

.auth__forgot {
    @apply flex text-[#1570EF] hover:text-[#1570EF] text-sm font-medium text-right mb-6 ml-auto w-max;
}

.auth__submit {
    box-shadow: none;
    @apply w-full rounded-full h-[44px] font-semibold text-base bg-[#18181B] hover:bg-[#3F3F46] disabled:opacity-50;
}
.auth__submit.ant-btn-loading {
    @apply w-full;
}
.auth__submit-message {
    @apply mt-[32px];
}

.auth__errorText {
    @apply text-[#df4444] mb-2.5 text-sm;
}

.auth__remembered {
    @apply text-sm font-medium text-[#475467] text-center mt-[32px];
}

.auth__remembered a {
    @apply text-[#1570EF] hover:text-[#1570EF] ml-0.5;
}

.auth__message-icon {
    @apply w-[108px] h-[108px] mx-auto mb-[32px] border-2 border-[#D1FADF] rounded-full bg-[#ECFDF3] flex justify-center items-center;
}

.auth__message-icon span {
    @apply text-[#62CA7A] text-[60px];
}

.auth__message-title {
    @apply text-xl font-semibold text-center text-primary mb-[12px];
}
