@import url('https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,100..900&display=swap');

@font-face {
    font-family: 'Stagnan';
    src: url('./fonts/stagnan-semibold.ttf') format('truetype');
    font-weight: 600;
}

@tailwind base;

@layer base {
    html {
        @apply font-inter;
    }
}

@tailwind components;
@tailwind utilities;

html::-webkit-scrollbar {
    display: none;
}

html {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.main-content {
    z-index: 999;
}

.custom-menu {
    .ant-menu-item {
        width: 204px;
        height: 50px;
        padding-left: 13px !important;
        padding-right: 13px !important;
        gap: 12px !important;
        border-radius: 6px;
        display: flex;
        align-items: center;
    }

    .ant-menu-item-selected span {
        color: #18181b !important;
    }

    .ant-menu-item-selected {
        background-color: #f4f4f5 !important;
        border-left: 2px solid #18181b;
    }

    .ant-menu-item span {
        margin-inline-start: 0 !important;
    }

    .ant-menu-title-content {
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        color: #18181b !important;
    }

    .ant-menu-item.\!m-0 {
        width: 100%;
        min-width: 50px;
    }

    .ant-menu-item.\!m-0:hover {
        width: 100%;
        min-width: 50px;
    }
}

.address-popover .ant-popover-arrow {
    display: none;
}

.address-popover .ant-popover-inner {
    border-radius: 4px;
}

.custom-dropdown {
    .ant-dropdown-menu {
        width: 158px !important;
        height: 92px !important;
        border-radius: 9px !important;
        gap: 4px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .ant-dropdown-menu-item {
        padding-left: 16px !important;
        padding-right: 16px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 40px;
    }

    .ant-dropdown-menu-item-selected {
        background-color: #e4e4e7 !important;
    }
}

/* Tabs component in Dashboard */
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom-color: #d1d1d6;
}

/* Drawer title  */
.transaction-settings .ant-drawer-header {
    border-bottom-color: #d1d1d6;
}

.transaction-settings .ant-drawer-body {
    padding: 12px;
    background-color: #f7f7f7;
}

/* Checkbox styles */
.ant-checkbox .ant-checkbox-inner:after {
    border-color: #18181b;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: white;
    border-color: #18181b;
}

.accounts-page .ant-table-wrapper .ant-table-column-title {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #51525c;
}

.accounts-page .ant-table-wrapper .ant-table-tbody > tr > td {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
}

.accounts-page .ant-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.accounts-page .ant-pagination-next {
    text-align: right;
    padding: 0 20px;
    flex: 1;
    height: auto;
}

.accounts-page .ant-pagination-prev {
    text-align: left;
    padding: 0 20px;
    flex: 1;
    height: auto;
}

.accounts-page .ant-pagination-item {
    flex: 0;
}

.accounts-page .ant-pagination-items {
    display: flex;
    justify-content: center;
    flex: 2;
}

.accounts-page .ant-pagination .ant-pagination-item-active {
    border-color: #e4e4e7;
}

.custom-select .ant-select-selector {
    padding: 1.2rem !important;
}

.multi-select .ant-select-selector {
    padding: 0.3rem !important;
}

.custom-select .ant-select-arrow {
    top: 60%;
}

.accounts-filter .ant-form-item {
    margin-bottom: 0 !important;
}

.accounts-filter .ant-form-item-label {
    width: 100% !important;
}

.account-details .ant-tabs .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.account-details .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
}

.fiat-tab .ant-tabs-nav-list {
    width: auto !important;
}

.transaction-detail .ant-tabs-nav-list {
    background: #f4f4f5;
    border-radius: 12px;
    padding: 5px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.hide-scrollbar .ant-tabs-nav-list::-webkit-scrollbar {
    display: none;
}

.transaction-detail .ant-tabs-tab {
    padding: 7px 20px;
}

.transaction-detail .ant-tabs-tab-active {
    background: #ffffff;
    padding: 0 20px;
    border-radius: 12px;
}

.transaction-detail .ant-tabs-nav::before {
    border: 0;
}

.transaction-detail .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
}

.transaction-detail .ant-tabs-ink-bar {
    display: none;
}

.modal-select {
    .ant-select-selector {
        padding: 1.5rem !important;
        width: 320px !important;
        border-radius: 1.5rem;
        background: #f4f4f5 !important;
        border: none !important;
    }

    .ant-select-arrow {
        top: 50%;
        font-size: 14px;
        color: #000;
    }
}

.address-select .ant-select-selector {
    outline: none !important;
    box-shadow: none !important;
    background: none !important;
    border: none !important;
}

.address-select .ant-select-selector:focus-visible,
.address-select .ant-select-selector:focus,
.address-select .ant-select-selector:active,
.address-select .ant-select-focused .ant-select-selector {
    outline: none !important;
    box-shadow: none !important;
    border: none;
}

/* Style for Authenticator Input */
.authCode {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
}

.authCode input {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 80px;
    width: 80px;
    font-size: 48px;
    color: #18181b;
    text-align: center;
    font-weight: 500;
    flex-shrink: 0;
    flex-grow: 0;
}

.authCode input:focus {
    border-color: #18181b;
    box-shadow:
        0px 0px 0px 4px #ededed,
        0px 1px 2px 0px #101828;
    outline: none;
}

.authCode input::placeholder {
    color: #d0d5dd;
}

.authCode input:not(:last-child) {
    margin-right: 12px;
}

/* transaction history */
.ant-table-content {
    overflow: auto;
}

.transaction-actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
}

.transaction-actions-btn {
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #efefef;
    background: #f9fafb;
    text-align: left;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.ant-select-dropdown .rc-virtual-list-scrollbar-visible {
    visibility: hidden;
}
.account-purpose-select .ant-select-selection-wrap {
    min-height: 40px;
}
.transaction-activity-tabs:hover {
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}
.white-label-css .ant-select-selector {
    background: #fbfbfb !important;
}
.white-label-css .ant-select-selection-placeholder {
    color: #51525c;
}
@media (max-width: 640px) {
    .transaction-actions-btn {
        padding: 16px;
    }

    .transaction-actions-icon {
        width: 40px !important;
        height: 40px !important;
    }

    .transaction-actions-title {
        font-size: 16px !important;
    }

    .create-transaction-model {
        max-width: 100% !important;
        height: 100% !important;
        margin: 0px !important;
        width: 100% !important;
    }

    .create-transaction-model .ant-modal-content {
        position: fixed !important;
        bottom: 0 !important;
        width: 100% !important;
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .create-transaction-model .common-model-body {
        height: 40vh !important;
    }
}

.create-transaction-fiat-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 24px;
}

.transaction-actions:not(.transaction-actions-fiat) .transaction-actions-btn:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
}

.transaction-actions-btn:before {
    content: '';
    position: absolute;
    width: 50%;
    padding-bottom: 50%;
    transform: scale(0);
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform-origin: 50% 50%;
    background: #6366f1;
    transition: all 0.3s;
    opacity: 0;
}

.transaction-actions-btn:hover:before {
    transform: scale(2.2);
    opacity: 1;
    background: black;
}

.transaction-actions-btn:hover .transaction-actions-icon {
    background: #fff;
}

.transaction-actions-icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: #e4e4e7;
}

.withdrawal-confirmation-icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: #e4e4e7;
}

.transaction-actions-btn:hover .transaction-actions-title,
.transaction-actions-btn:hover .transaction-actions-text {
    color: #fff;
}

.transaction-actions-btn > * {
    position: relative;
    z-index: 1;
}

.transaction-actions-title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 16px;
    transition: all 0.3s;
    color: #000;
}

.transaction-actions-text {
    color: #475467;
    margin-top: 16px;
    position: relative;
    transition: all 0.3s;
}

/* responsive account tab */
.account-tabs-mob .ant-tabs-nav-list {
    background: #f7f7f7;
    transform: none !important;
    transition: none !important;
    overflow-x: auto !important;
}

.account-tabs-mob .ant-tabs-nav {
    border: 1px solid #e4e4e7;
    padding: 20px 12px;
    margin: 0;
}

.account-tabs-mob .ant-tabs-tab {
    padding: 5px 15px;
    font-size: 15px;
    font-weight: 500;
}

.account-tabs-mob .ant-tabs-tab-active {
    background: #e4e4e7;
    padding: 0 15px;
    border-radius: 12px;
    border-bottom: 2px solid #18181b;
}

.modal-form .ant-row {
    align-items: flex-start;
}

.modal-form .ant-picker-input > input {
    font-size: 16px !important;
}

.modal-form .additional-info .ant-row {
    align-items: flex-start;
}

@media (min-width: 576px) {
    .modal-form .ant-form-item-control-input-content > div {
        width: 320px !important;
    }

    .registeration-form .ant-form-item-label {
        width: 135px !important;
        margin-right: 32px !important;
    }
}

.form-input .ant-form-item-label {
    display: flex !important;
    align-items: center !important;
    width: 130px;
    font-size: 16px;
    height: 40px !important;
}

.form-input .ant-row {
    align-items: flex-start;
}

.form-input .ant-picker-input > input {
    font-size: 16px !important;
}

.form-input .additional-info .ant-row {
    align-items: flex-start;
}

@media (min-width: 640px) {
    .form-input .ant-form-item-control-input-content > div {
        width: 380px !important;
    }
}

.custom-select-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-search-icon {
    position: absolute;
    left: 10px;
    top: 60%;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
    /* Ensure the icon doesn't interfere with clicks */
}

.filter-select .ant-select-selector {
    padding: 1.2rem 0 1.2rem 10px !important;
}

.filter-select .ant-select-selection-placeholder {
    padding-left: 25px !important;
    /* Ensure placeholder text is not overlapped by the icon */
}

.filter-select .ant-select-selection-search {
    padding-left: 25px !important;
    /* Ensure placeholder text is not overlapped by the icon */
}

.filter-select .ant-select-selection-item {
    padding-left: 25px !important;
    /* Ensure placeholder text is not overlapped by the icon */
}

.filter-select .ant-select-selection-overflow-item {
    max-width: 90% !important;
}
.account-purpose-select .ant-select-selector {
    background: #f4f4f5;
}

@media (max-width: 767px) {
    .modal-select {
        .ant-select-selector {
            width: 100% !important;
        }
    }

    /* .ant-modal-content {
        height: 98vh;
        padding: 16px !important;
    } */
    .common-mobile-view {
        max-width: 100% !important;
        height: 100% !important;
        margin: 0px !important;
        width: 100% !important;
    }

    .common-mobile-view .ant-modal-content {
        height: 100vh !important;
        padding: 16px !important;
        padding-bottom: 0px !important;
        border-radius: 0px !important;
    }

    .common-mobile-view .common-model-body {
        height: 75vh;
    }

    .merchant-mobile-view .common-model-body {
        height: 78vh !important;
    }

    .create-transaction-view .common-model-body {
        height: 91vh !important;
    }

    .sell-crypto-modal.ant-modal .ant-modal-footer {
        position: absolute;
        bottom: 16px;
        width: calc(100% - 32px);
    }

    .account-model-view .common-model-body {
        height: 80vh !important;
    }
}

.fiat-balances {
    display: flex !important;
    overflow: scroll;
    width: 100%;
}

.fiat-balance-freez-btn {
    min-width: 36px !important;
    min-height: 36px !important;
}

@media (max-width: 767px) {
    .fiat-balances {
        display: none !important;
    }
}

.add-account-select .ant-select-selector {
    border-radius: 24px;
    padding: 24px 15px !important;
    /* display: flex;
    justify-content: center; */
    background-color: #f4f4f5 !important;
    color: #18181b !important;
}

.add-account-select span.ant-select-arrow {
    padding-top: 10px;
}

.add-account-select span.ant-select-clear {
    padding-top: 10px;
}

.date-menu li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: #e4e4e7;
    border-radius: 6px;
    border-bottom-width: 0 !important;
    border-bottom-color: #000 !important;
}

.date-menu:hover
    li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-hover.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: #e4e4e7;
    border-radius: 6px;
}

@media (min-width: 768px) {
    .filter-range-picker {
        border-bottom-color: black;
        border-bottom-width: 2px;
        background-color: #d1d1d6;
    }
}

.calender-range-picker
    .ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled)
    .ant-picker-cell-inner,
.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled)
    .ant-picker-cell-inner,
.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled)
    .ant-picker-cell-inner {
    color: black;
    background: #e4e4e7;
}

.calender-range-picker.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
    background: #f4f4f5;
}

.calender-range-picker
    .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
    background: #f4f4f5;
}

.transaction-modal .ant-modal-body {
    max-height: 500px;
    max-width: 522px;
    margin: 0 8px;
    overflow: hidden !important;
}

.modal-form .ant-form-item-label {
    display: flex !important;
    align-items: center !important;
    width: 130px;
    font-size: 16px;
    height: 40px !important;
}

.transaction-form-FX .ant-form-item-label {
    width: 170px !important;
}

.modal-form label.ant-form-item-required.ant-form-item-no-colon {
    display: flex !important;
    align-items: flex-start !important;
    width: 150px;
    font-size: 16px;
    height: auto !important;
    position: relative;
}

.modal-form label.ant-form-item-required.ant-form-item-no-colon:before {
    line-height: inherit !important;
    position: absolute;
    left: -7px;
}

.modal-form .ant-form-item .ant-form-item-label {
    overflow: initial;
}

.modal-form.ant-form-item-row {
    display: flex;
}

.modal-form .ant-upload-list-item {
    border-radius: 4px;
    /* Rounded corners for the list items */
    margin-top: 8px;
}

.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 100%;
    margin-inline-end: 8px;
}

.withdrawal-modal .ant-modal-body {
    min-height: 250px;
    max-height: 500px;
    max-width: 522px;
}

.transaction-modal .modal__footer {
    border-top: 1px solid #d1d1d6;
}

:where(.css-dev-only-do-not-override-l2rora).ant-form-item .ant-form-item-label > label {
    font-size: 16px;
    white-space: normal;
    text-align: left;
}

.custom-table {
    @apply border-[#E4E4E7] border-[1px] border-solid;
    border-top: 0;
}

.desktop-page-block {
    @apply border-[#E4E4E7] border-[1px] border-solid;
}

.settings-tab .custom-table {
    @apply border-0;
    border-top: 1px solid #e4e4e7;
}

.custom-table .ant-table-thead > tr > th {
    white-space: nowrap;
    background-color: #f7f7f7;
}

.custom-table .ant-table-container {
    overflow-x: auto;
}

.custom-table .ant-table {
    min-width: 100%;
}

.custom-table .ant-pagination-item {
    display: flex;
    justify-content: center;
}

.custom-table .ant-pagination-item:hover,
.custom-table .ant-pagination-item-active {
    padding: 0 20px !important;
}

.account-details .ant-tabs-content-holder {
    height: 80vh;
    overflow: auto;
}

.account-details .ant-tabs-content-holder::-webkit-scrollbar {
    display: none;
}

.transaction-detail .ant-tabs-content-holder {
    height: auto;
}

.csv-modal .ant-modal-content {
    padding: 0;
    height: auto;
    overflow: hidden;
    display: flex;
}

.transaction-tag .wrapped-text {
    white-space: pre-wrap;
    word-break: break-all;
}

.transaction-result .ant-modal-content {
    margin: 0;
    padding: 0;
    height: auto !important;
    padding: 0 !important;
}

.transaction-result .ant-modal-content .ant-modal-body {
    margin: 0;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
    background: #d9d9d9;
}
.account-pupose-form .ant-form-item-label .ant-form-item-required {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: #51525c;
}
.account-pupose-form .ant-form-item-label > label {
    color: #51525c;
}
.account-purpose-select .ant-select-selection-placeholder {
    color: #70707b;
}
.account-pupose-form .ant-select-arrow {
    color: #18181b;
}
.ant-select-selector .ant-select-selection-item {
    background-color: transparent !important;
}

@media (max-width: 575px) {
    .transaction-modal .ant-modal-body {
        max-height: 100vh;
        height: 92%;
    }

    .transaction-modal .ant-modal-content {
        padding: 16px;
        height: 100vh;
        border-radius: 0;
    }

    .transaction-modal {
        margin: 0 !important;
        max-width: 100vw !important;
        height: 100vh;
    }

    .deposit-modal.ant-modal .ant-modal-footer {
        position: absolute;
        bottom: 16px;
        width: calc(100% - 32px);
    }
}

@media (min-width: 1024px) {
    .create-transaction-view .common-model-body {
        height: 75vh !important;
    }

    .account-model-view .common-model-body {
        max-height: 65vh !important;
    }
}

@media (max-width: 1024px) {
    .fiat-transaction-tab {
        padding-left: 0px;
        padding-right: 0px;
    }

    .fiat-transaction-tab-details {
        padding-top: 0px;
        margin-top: 24px;
        border: 0px;
        border-top: 1px solid #e4e4e7;
        border-radius: 0px;
    }

    .transaction-summary {
        padding: 24px 12px 0px 12px;
    }

    .fiat-tab {
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 24px;
    }

    .crypto-tab {
        padding-left: 8px;
        padding-right: 8px;
        margin-bottom: 24px;
    }

    .fiat-tab-tabs {
        padding-top: 18px;
        cursor: pointer;
        gap: 8px;
        align-items: center;
    }

    .crypto-tab-tabs {
        padding-top: 18px;
        cursor: pointer;
        gap: 8px;
        align-items: center;
    }

    .fiat-tab .ant-tabs-nav {
        padding: 0px !important;
        border-radius: 12px;
        background: #fff;
        border: none;
    }

    .crypto-tab .ant-tabs-nav {
        padding: 0px !important;
        border-radius: 12px;
        background: #fff;
        border: none;
    }

    .fiat-tab .ant-tabs-nav-list {
        /* width: auto !important; */
        overflow: scroll;
        padding: 2px;
        height: 40px;
        background-color: #f4f4f5;
        border: 1px solid #f4f4f5;
    }

    .crypto-tab .ant-tabs-nav-list {
        overflow: scroll;
        padding: 2px;
        height: 40px;
        background-color: #f4f4f5;
        border: 1px solid #f4f4f5;
    }

    .fiat-tab .ant-tabs-tab-active {
        background-color: #ffffff;
        border-radius: 6px;
        border-bottom: 0px;
    }

    .crypto-tab .ant-tabs-tab-active {
        background-color: #ffffff;
        border-radius: 6px;
        border-bottom: 0px;
    }

    .fiat-balance {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px !important;
    }

    .fiat-balance-details {
        padding-top: 24px !important;
    }

    .balance-freeze {
        height: 36px;
        margin-right: 0px;
    }

    .create-transaction-model .common-model-body {
        height: auto !important;
    }
}

@media (max-width: 575px) {
    .transaction-detail .ant-tabs-nav-list {
        /* width: 100%; */
        display: flex;
        justify-content: space-between;
    }

    .fiat-transaction-tab {
        padding-left: 0px;
        padding-right: 0px;
    }

    .fiat-transaction-tab-details {
        padding-top: 0px;
        margin-top: 0px;
        border: 0px;
    }

    .transaction-summary {
        padding: 24px 0px 0px 0px;
    }

    .fiat-tab {
        padding-left: 12px;
        padding-right: 12px;
    }

    .fiat-tab-tabs {
        padding-top: 18px;
    }

    .fiat-tab .ant-tabs-nav {
        padding: 0px !important;
        border-radius: 12px;
    }

    .fiat-tab .ant-tabs-nav-list {
        width: 100% !important;
        overflow: scroll;
        padding: 2px;
        height: 36px;
    }

    .crypto-tab .ant-tabs-nav-list {
        height: 36px;
    }

    .fiat-tab .ant-tabs-tab-active {
        background-color: #ffffff;
        border-radius: 6px;
        border-bottom: 0px;
    }

    .fiat-balance {
        padding-left: 0px;
        padding-right: 0px;
    }

    .balance-freeze {
        height: 36px;
        margin-right: 0px;
    }

    .crypto-wallets {
        padding-left: 0px;
        padding-right: 0px;
        border-top: 1px solid #e4e4e7;
        margin-top: 10px;
    }

    .wallet-heading {
        font-size: 16px;
    }
}

@media (min-width: 575px) {
    .ant-form-item-label {
        width: 140px;
    }
    .account-pupose-form .ant-form-item-label {
        width: auto;
    }
}

.custom-rounded-select .ant-select-selector {
    border-top-right-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
    background-color: #f4f4f5 !important;
    border-width: 0.5px !important;
}

.full-rounded-select.ant-select-single .ant-select-selector {
    background-color: #f4f4f5;
    border: none;
    border-radius: 25px;
}

.tabs-custom .ant-tabs-nav-list {
    background: #f4f4f5;
    border-radius: 8px;
    padding: 2px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.tabs-custom .ant-tabs-tab {
    width: 50%;
    justify-content: center;
}

.tabs-custom .ant-tabs-tab-active {
    background: #ffffff;
    padding: 0 20px;
    border-radius: 7px !important;
}

.tabs-custom .ant-tabs-nav::before {
    border: 0;
}

.tabs-custom .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
}

.tabs-custom .ant-tabs-ink-bar {
    display: none;
}

.custom-beneficiary-list .ant-list-item {
    border-radius: 10px;
    margin-bottom: 16px;
    border: 1px solid #d9d9d9;
    padding: 12px 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
}

.custom-beneficiary-list .ant-list-item:hover {
    background-color: #f0f0f0;
}

.custom-beneficiary-list .ant-list-item-action {
    margin-left: auto;
}

.custom-beneficiary-list .ant-list-item-meta-avatar {
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border-radius: 50%;
    padding: 8px;
}

.custom-beneficiary-list .ant-list-item-meta-title,
.custom-beneficiary-list .ant-list-item-meta-description {
    margin-bottom: 0;
}

.withdrawal-form__input_amount .ant-form-item-control-input {
    width: 360px;
}

.delete-bank-account-modal .ant-modal-content {
    padding: 0;
    border-top: 3px solid #f04438;
}

.settings-tab .ant-tabs-nav {
    margin-left: 24px !important;
    margin-right: 24px !important;
}

.settings-table .ant-table-thead .ant-table-cell {
    color: var(--Gray-iron-600, #51525c) !important;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
    /* 142.857% */
}

.svg-red {
    filter: invert(32%) sepia(97%) saturate(7472%) hue-rotate(359deg) brightness(98%) contrast(105%);
}

.delete-modal .ant-modal-content {
    margin: 0;
    padding: 0;
    height: auto !important;
    padding: 0 !important;
}

.white-label-modal .ant-modal-content {
    padding: 0;
}

@media (max-width: 640px) {
    .white-label-modal .ant-modal-body {
        max-height: 100vh;
        height: 92%;
    }

    .white-label-modal .ant-modal-content {
        padding: 0 !important;
        height: 100vh;
        border-radius: 0;
    }

    .white-label-modal {
        margin: 0 !important;
        max-width: 100vw !important;
        height: 100vh;
    }

    .white-label-modal.ant-modal .ant-modal-footer {
        position: absolute;
        bottom: 8px;
        width: 100%;
        background-color: white;
    }
}

.filter-drawer,
.crypto-wallet-mobile-drawer .ant-drawer-header-title {
    @apply flex flex-row-reverse justify-between;
}

.crypto-wallet-mobile-drawer .ant-drawer-close {
    color: black;
    margin-right: 0;
}

.reset-filter-btn {
    @apply px-5 text-[16px] lg:text-[14px] h-[42px] font-medium;
}

.search-filter-btn {
    @apply px-5 text-[16px] lg:text-[14px] h-[42px] bg-primary text-white font-medium;
}

@media screen and (max-width: 639px) {
    .filter-drawer .ant-drawer-header,
    .filter-drawer .ant-drawer-body {
        padding: 16px;
    }

    .filter-drawer .ant-drawer-footer {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.calender-range-picker .ant-picker-panel-container {
    .ant-picker-presets {
        min-height: 25rem !important;
    }

    @media (max-width: 768px) {
        overflow: scroll !important;
        height: 320px;

        .ant-picker-panel-layout {
            flex-direction: column !important;

            .ant-picker-presets {
                max-width: 100% !important;
                min-height: 10rem !important;
            }

            .ant-picker-panels,
            .ant-picker-datetime-panel {
                flex-direction: column !important;
            }
        }
    }
}

.jncAlert {
    font-size: 14px;
    margin: 0px;
    padding: 5px;
    color: #f04438;
    display: grid;
    justify-content: end;
}

.bg-input {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.transaction-modal textarea:focus,
input:focus {
    outline: none;
}

.merchant-account-form__input {
    @apply w-full lg:w-[320px] h-[50px] text-base rounded-3xl p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[24px];
}

.merchant-account-form__label {
    @apply text-sm lg:text-base w-[120px] text-wrap leading-5 text-start overflow-visible;
}

.merchant-account-form__input-container {
    @apply flex items-center gap-[30px];
}

:where(.merchant-dragger).ant-upload-wrapper .ant-upload-drag {
    @apply border-solid;
}

.crypto-fee__form-input {
    @apply w-full rounded-3xl p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[15px] px-[10px];
}

.crypto-transaction {
    @apply flex items-center justify-between pt-4 lg:p-0 lg:px-0;
}

.crypto-wallets {
    @apply flex items-center justify-between pt-4 lg:p-0 lg:px-0;
}

.crypto-wallets-btn {
    @apply p-2 mr-3;
}

.crypto-wallet-item {
    @apply flex justify-between border-t-[1px] pt-[12px] mt-[12px] items-center;
}

.sell-crypto-div {
    @apply flex justify-between items-center max-sm:flex-wrap;
}

.sell-crypto-input-btn {
    @apply bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-6;
}

.sell-crypto-label {
    @apply text-base font-normal text-start;
}

.sell-crypto-label-value {
    @apply max-sm:pt-3 flex items-center max-sm:w-full;
}

.sell-crypto-title {
    @apply mb-[30px] flex justify-between items-center;
}

.sell-crypto-value {
    @apply py-2 text-base font-normal flex items-center gap-2;
}

.sell-crypto-confirm {
    @apply py-2 flex sm:justify-between items-center;
}

.sell-crypto-confirm-p {
    @apply text-base font-normal text-[#51525C];
}

.sell-crypto-confirm-span {
    @apply hidden sm:block text-xl font-normal text-[#26272B];
}

.sell-crypto-confirm-title {
    @apply sm:flex justify-between items-center;
}

.sell-crypto-confirm-value {
    @apply py-2 text-base font-medium text-[#18181B];
}

.sell-crypto-confirm-currency {
    @apply pl-2 text-xl font-semibold text-[#26272B];
}

.copy-icon svg path,
.copy-icon svg rect {
    transition: 0.15s;
}

.copy-icon:hover svg path,
.copy-icon:hover svg rect {
    stroke: rgba(21, 112, 239, 1);
}

.ant-switch {
    min-width: 36px;
}

.ant-switch:not(.ant-switch-checked),
.ant-switch:not(.ant-switch-checked):hover {
    background: rgba(24, 24, 27, 1);
}

.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover {
    background: rgba(18, 183, 106, 1) !important;
}

.primary-btn-style {
    @apply hover:bg-[#403e46] py-2 px-3 h-auto bg-[#18181B];
    color: #fff !important;
    box-shadow: none;
}

.ant-btn {
    box-shadow: none;
    height: 40px;
}

.ant-btn .ant-btn-icon {
    flex-shrink: 0;
}

.ant-btn.ant-btn-lg {
    height: 44px;
    font-size: 16px;
    padding: 0 32px !important;
}

.ant-btn.ant-btn-round {
    padding: 0 32px !important;
}

.ant-btn-loading {
    width: 100% !important;
}

.ant-btn:disabled {
    background: #e4e4e4;
    color: #fff;
}

.ant-btn-primary {
    border: 0 !important;
}

.ant-btn-primary:hover:not(:disabled) {
    background: #403e46 !important;
}

.and-btn-min-width {
    @apply min-w-[160px];
}

.close-icon {
    color: #70707b;
    /* Default color */
    transition: color 0.3s ease;
    /* Smooth color transition */
}

.close-icon:hover {
    color: #000;
    /* The color you want when hovered, change this to any color */
}

.crypto-wallet-item-mobile {
    display: none;
}

@media (max-width: 767px) {
    .crypto-wallet-item {
        display: none;
    }

    .crypto-wallet-item-mobile {
        @apply flex justify-between border-t-[1px] pt-[12px] mt-[12px] items-center;
    }
}

.crypto-wallet-mobile-drawer-container {
    @apply border-[1px] p-5 rounded-[12px] bg-gray-50;
}

.crypto-wallet-address {
    @apply px-[12px] py-[8px] border-[1px] rounded-[8px] hover:bg-[#F4F4F5] cursor-pointer;
}

.crypto-wallet-freeze-icon-container {
    @apply flex items-center justify-center w-[40px] h-[40px] bg-gray-200 rounded-full;
}

.main-page-title {
    @apply text-[20px] md:text-[30px] font-medium;
}

.filter-drawer .ant-drawer-title,
.page-title {
    @apply text-[16px] md:text-[18px] font-semibold;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child,
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child,
.ant-table-wrapper .ant-table-container {
    border-radius: 0;
}

.ant-table-thead .ant-table-cell {
    @apply text-[#51525c];
}

.dashboard-tabs,
.dashboard-header {
    @apply p-[12px] sm:p-6 bg-background-primary;
}

.dashboard-tabs .ant-tabs-nav {
    @apply mb-[20px] sm:mb-[24px];
}

.details-top {
    @apply grid grid-cols-[1fr_auto] gap-[15px] items-center pt-4;
}

.details-top-title {
    @apply font-semibold;
}

.wallet-item-btn-mobile {
    @apply px-[12px] py-[8px] mr-3 rounded-[99px] text-[16px] w-full flex flex-row-reverse mt-[20px];
}

.address-select:focus {
    outline: none;
}

.address-confirm {
    border-top: 3px solid #f79009;
    border-radius: 8px;
}

.sidebar-menu {
    position: relative;
    width: 100%;
    padding: 24px 16px 30px;
    align-items: center;
    display: grid;
    grid-template-columns: 50px 1fr;
    justify-items: center;
    grid-gap: 2px;
}

.header-banner-bg-dev {
    background-color: blue;
}

.header-banner-bg-prod {
    background-color: red;
}

.sidebar-logo-block {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.sidebar-logo {
    /* @apply !min-w-[24px] !min-h-[24px]; */
    max-width: initial;
    width: 152px;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.sidebar-logo.active {
    opacity: 1;
}

.ant-pagination-prev .ant-btn-default .ant-btn-icon {
    @apply mr-[8px];
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    @apply text-white font-normal;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) svg {
    color: white;
}

.ant-tag-borderless {
    @apply bg-[#f4f4f5] text-[#51525c] flex items-center;
}

.ant-tag-borderless svg {
    @apply w-[12px] h-[12px];
}

.filter-tags {
    @apply px-[12px] lg:px-0 flex flex-wrap items-center relative left-[-4px];
}

.filter-tags .ant-tag-borderless {
    @apply m-[4px];
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
    box-shadow: none;
}

.details-row {
    @apply grid sm:max-lg:grid-cols-[118px_1fr] xl:grid-cols-[118px_1fr] gap-[10px] py-[2px];
}

.details-row-value {
    @apply sm:max-lg:text-end xl:text-end;
}

.accounts-page .ant-table-cell p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.accounts-page .ant-table-cell p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

@media screen and (max-width: 390px) {
    .dashboard-header {
        flex-direction: column;
    }
}

.crypto-balance .ant-table-thead {
    border-radius: 0 0 0 0;
}

:where(.crypto-balance).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0;
    /* Removing the border radius */
}

:where(.crypto-balance).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0;
}

.merchant-transfer-item {
    @apply flex items-center justify-between w-full p-4 border rounded-[8px] hover:border-[#18181B];
}

.dateRangePicker .ant-picker-panel:nth-child(2) {
    display: none;
}

.dateRangePicker .ant-picker-panel:nth-child(1) button {
    visibility: visible !important;
}

.balance-history-range-picker .ant-picker {
    display: flex;
    align-items: center;
}

.balance-history-range-picker .ant-picker-suffix {
    order: -1;
    /* Move the suffix icon to the start */
    margin-right: 8px;
    /* Add space between the icon and input */
}

.not-found {
    height: 100vh;
    width: 100vw;
    background: radial-gradient(100% 100% at 50% 0, #282828 0, #000 100%);
    overflow: hidden;
}

.capitalize-first-letter::first-letter {
    text-transform: uppercase;
}

@media (min-width: 744px) and (max-width: 1024px) {
    .noteModalTabview .common-model-body {
        height: 318px !important;
    }
}

.resize-none {
    resize: none !important;
}

.ant-btn > span:not(:only-child) {
    align-self: center;
}

.pagination-btn:disabled {
    background-color: white;
}

.journeyStatusDropdown .ant-dropdown-menu-item-selected {
    @apply text-black bg-gray-200 !important;
}

.dashboard-tabs .ant-tabs-tab {
    padding-bottom: 18px !important;
}

.transaction-form .ant-checkbox {
    border: 2px solid black;
}

.errorFields .ant-form-item-additional {
    width: max-content !important;
}
.ant-pagination .ant-btn:disabled {
    cursor: not-allowed !important;
}

.maintenance-page .ant-collapse-header {
    align-items: center !important;
}

.maintenance-page .ant-collapse-header {
    padding: 0 !important;
}

.maintenance-page .ant-collapse-extra {
    height: 64px;
}

@media (max-width: 768px) {
    .maintenance-page .ant-collapse-header {
        align-items: start !important;
    }
    .maintenance-page .ant-collapse-extra {
        padding-top: 16px;
    }
}

.maintenance-page .ant-collapse-content-box {
    border-top: 1px solid #e4e4e7;
}

.custom-Dashboard-scrollbar::-webkit-scrollbar {
    height: 5px;
}

.custom-Dashboard-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}
