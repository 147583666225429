.notif {
    grid-template-columns: 1fr;
    @apply lg:grid bg-white rounded-xl flex-col hidden lg:flex;
}

.notif.has-details {
    display: grid;
    grid-template-columns: 1fr 420px;
}

.notif__count {
    background: rgba(240, 68, 56, 1);
    padding: 6px;
    padding-right: 8px;
    color: white;
    border-radius: 100%;
    margin-left: 8px;
    line-height: 1;
    @apply lg:text-[14px] text-[11px];
}

.notif .page-title {
    display: flex;
    align-items: center;
}

.notif-new {
    @apply bg-[#f04438] w-[6px] h-[6px] rounded-full mr-[8px] lg:mr-[12px];
}

.notif__table p {
    @apply line-clamp-1 text-ellipsis;
}

.notif__table .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
}

.notif__table .ant-pagination-next,
.notif__table .ant-pagination-prev {
    height: auto;
    flex: 1;
}
.notif__table .ant-pagination-next {
    text-align: right;
}

.notif__details {
    border-left: 1px solid #d1d1d6;
    overflow-y: auto;
    max-height: calc(100vh - 32px);
    min-height: 100%;
}

.notif__details-list {
    @apply md:grid md:grid-cols-2 md:gap-x-[20px] lg:block;
}

.notif .active-row,
.notif .active-row:hover {
    background: rgba(250, 250, 250, 1);
}

.notif__details-item {
    display: grid;
    grid-template-columns: 168px 1fr;
    align-items: center;
    padding: 12px 0;
    @apply lg:gap-[15px] gap-[8px];
}
.notif__details-item:first-child {
    padding-top: 0;
}

.notif__details-item:last-child {
    @apply col-start-1 col-end-3 lg:col-auto pb-0 lg:pb-[12px];
}

.notif__details-item.one-row {
    grid-template-columns: 1fr;
}

.notif__details-item p:first-child {
    color: rgba(81, 82, 92, 1);
}

.notif__details-item:not(:last-child) {
    border-bottom: 1px solid rgba(228, 228, 231, 1);
}
.notif__details-item:not(.one-row) p:last-child,
.notif__details-item:not(.one-row) div:last-child {
    text-align: right;
    margin-left: auto;
}

.notif__row {
    @apply bg-white border-b border-[#E4E4E7] px-[12px] sm:px-[24px] py-[12px];
}

.notif-select .ant-select-selection-placeholder,
.notif-select input::placeholder {
    font-size: 14px;
    color: rgba(112, 112, 123, 1) !important;
}

.notif-select-icon .anticon svg {
    width: 18px;
    height: auto;
    fill: rgba(81, 82, 92, 1);
}

.notif__form {
    @apply grid grid-cols-4 gap-[16px];
}

.notif__form .ant-form-item {
    margin-bottom: 0;
}

.notif-moreInformation {
    width: 100% !important;
    max-width: 100%;
}

.notif-moreInformation .ant-modal-header {
    margin-bottom: 20px;
}

@media screen and (max-width: 1420px) {
    .notif__form.has-details {
        @apply grid-cols-2;
    }
}

@media screen and (max-width: 1360px) {
    .notif.has-details {
        grid-template-columns: 1fr 350px;
    }
    .notif__details-item {
        grid-template-columns: 1fr;
    }
    .notif__details-item:not(.one-row) p:last-child,
    .notif__details-item:not(.one-row) div:last-child {
        text-align: left;
        margin-left: initial;
    }
}

@media screen and (max-width: 767px) {
    .notif-moreInformation .ant-modal-content {
        padding: 20px;
    }
}
